<template>
  <div class="ps-section__right">
    <form class="ps-form--account-setting" method="get">
      <div class="ps-form__header">
        <h3>Edit User</h3>
      </div>
      <UserForm ref="userForm" :user-id="$route.params.id"></UserForm>
    </form>

  </div>
</template>

<script>
import UserForm from "./UserForm";

export default {
  name: "EditUser",
  components: {UserForm},
}
</script>

<style scoped>

</style>